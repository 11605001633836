/* body{
    background-color: #fff;
    margin: 0;
    overflow: unset !important;
    
  } */
  /* para graph ..... */
  .para{
    /* 
    overflow: hidden;

width: 100%;

*/
line-height: 1.6rem;
overflow: hidden;
/* display: -webkit-box;  */
-webkit-line-clamp: 4;
-webkit-box-orient: vertical;
max-height: 5rem;
text-overflow: ellipsis !important;
/* white-space: nowrap;  */
display: inline-block; 
 }
 .paramob{
  line-height: 1.6rem;
overflow: hidden;
/* display: -webkit-box;  */
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
max-height: 4rem;
text-overflow: ellipsis !important;
/* white-space: nowrap;  */
display: inline-block; 

 }
  
hr{
    width: 94%;
}
html {
    scroll-behavior: smooth;
  }
  .title{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }
  .sticky {
    position: fixed;
    top: 75px;
    width: 100%;
    z-index: 5;
   
  }
  .stickymob {
    position: fixed;
    top: 75px;
    width: 100%;
    z-index: 5;
   
  }
  Button.dis:hover{
    cursor: not-allowed !important;
    background-color: gainsboro;
    color: gainsboro;
  }
  Button.dis{
    background-color: gainsboro;
  }
  .dis{
    background-color: gainsboro;
    color: gray !important;
  }
  .MuiMenu-list{
    height: 300px;
    /* overflow-y: "scroll"; */
    

    /* sx={{ height: "400px",
      
      "&::-webkit-scrollbar": {
        width: "0.2em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px hsl(206, 92%, 94%)",
        webkitBoxShadow: "inset 0 0 6px hsl(206, 92%, 94%)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "hsl(206, 92%, 94%)",
        outline: "1px solid hsl(206, 92%, 94%)",
      },}} */
      
  }
   /* .content {
    padding: 200px 0px 0px 0px !important;
  } */
  .mobile{
    text-decoration: none;
    color: #0b3694;
    width:100%;
  }
  .title{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif !important;
  }
.alphaheading{
    font-size: 2.5rem !important;
    font-weight: 500 !important;
    margin-left: 45px !important;
}
.link{
    text-decoration: none;
    color:#fff;
}
.link{
    transition: all .3s ease-in-out;
}
.link:hover{
    border-top: 4px solid #3d9fff;
    color: #3d9fff;
    padding-top: 17px;
}
.link:hover{
    color:aqua
}
.card:hover{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.card{
    /* background-image: url(https://www.cognigy.com/hubfs/Cognigy%20V2/Vector-1.svg); */
    /* background-repeat: no-repeat; */
    /* background-position: top right; */
    /* background-size: 141px 152px; */
    
    border: 1px solid rgba(11,54,148,.15);;
    border-radius: 6px;
    padding: 30px;
    transition: all .3s ease-in-out;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
    transition-property: all;
    cursor: pointer;
    /* margin-left: 30px; */
    
}
.learnmore{
    background: url(https://www.cognigy.com/hubfs/Cognigy%20V2/Dark-Blue-right-arrow.svg);
    background-repeat: no-repeat;
   
    /* background-size: contain;
    bottom: 0;
    content: "";
    height: 10.8px;
    left: -18.5px;
    position: absolute;
    top: 5px;
    transition: all .4s ease-in-out;
    width: 11.5px;
    z-index: 1; */
}
@media only screen and (max-width: 600px) {
    hr{
        width: 650px;
    }
  }
  @media (max-width: 767px) {
    html,body{
      overflow-x: hidden;
    }
  }
  /* @media screen and (min-width: 1360px) {
    .aplha{
      background-color: #0b3694 !important;
    }
  } */
  